import { Stylesheet } from "@fluentui/react/lib/Styling"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { MemoryRouter } from "react-router-dom"

import {
    ComponentName,
    registerDefaultApplicationComponents
} from "@encoway/cui-application-components"
import { registerDefaultConfiguratorComponents } from "@encoway/cui-configurator-components"

import { App } from "./App"
import { store } from "./app/store"
import { WagoVisualization } from "./components/WagoVisualization"

window.encoway = {
    baseUrl: window.encoway.baseUrl || "",
    initialized: false,
    startConfiguration: (productId: string, elementId: string): void => {
        if (window.encoway.baseUrl === "") {
            console.error(
                "Configuration cannot be started! No base url is set!"
            )
            return
        }
        if (!window.encoway.initialized) {
            registerDefaultApplicationComponents().override(
                ComponentName.Visualization,
                WagoVisualization
            )
            registerDefaultConfiguratorComponents()

            Stylesheet.getInstance().setConfig({ namespace: "encoway" })

            window.encoway.initialized = true
        }

        const container = document.getElementById(elementId)
        if (container !== null) {
            ReactDOM.unmountComponentAtNode(container)
        }

        ReactDOM.render(
            <Provider store={store}>
                <MemoryRouter>
                    <App productId={productId} />
                </MemoryRouter>
            </Provider>,
            document.getElementById(elementId)
        )
    }
}
