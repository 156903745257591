import { IAppSettings } from "@encoway/cui-application-components"

import { FloorPlanUpload } from "./components/Configuration/FloorPlanUpload/FloorPlanUpload"
import { getTheme } from "./theme"

// FIXME: Workaround, must be removed if CPQ-5556 is resolved
type CustomIAppSettings = IAppSettings & {
    visualization: {
        settings: {
            ui: {
                loadingSpinner: {
                    label: string
                }
                message: {
                    contextMenu: boolean
                    boundingBox: boolean
                    annotation: boolean
                    messageBar: {
                        fixedWidth: boolean
                    }
                }
                dimensioning: {
                    lineColor: string
                    textColor: string
                    autoEnabled: string | boolean
                }
                catalog: {
                    productId: boolean
                    addButton: boolean
                    infoButton: boolean
                    imageFit: string
                    with: null
                }
                mode: {
                    anchorOnly: boolean
                }
                environment: string
                environmentIntensity: number
                anchors: {
                    default: string
                    ok: string
                    error: string
                }
                components: {
                    sidebar: string
                }
                widgets: {
                    progressSpinner: string
                    annotations: string
                    messages: string
                    orientationIndicator: boolean
                    renderStatistics: string
                }
                progressSpinner: {
                    size: string
                    labelPosition: string
                }
                hud: boolean
                keyboardShortcuts: {
                    cloudGraph: string
                    exportGeometry: string
                    transformMode: string
                }
            }
        }
    }
}

export const getSettings = (articleName: string): CustomIAppSettings => ({
    locale: "de-DE",
    configuration: {
        options: {
            articleName
        },
        settings: {},
        baseUrl: window.encoway.baseUrl
    },
    visualization: {
        mode: "assembly",
        baseUrl: "https://visualization.encoway.cloud/vcs",
        token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXN1YWxpemF0aW9uIiwiYXVkIjoiaHR0cHM6Ly93d3cud2Fnby5jb20vZGUvIiwiaXNzIjoiV0FHTyIsImlhdCI6MTcwOTY1NjQ4OSwiZXhwIjoxODA0MjA0ODAwLCJjYWQiOltdLCJ0b29scyI6bnVsbCwicmVuZGVyaW5nIjoic2QiLCJhciI6ZmFsc2V9.7FcNOV_ouGSHhcf9Ash2HosDJN5tBNtZRknravnzdaM",
        version: "0.0.2-wago", // "1.11.0",
        settings: {
            theme: getTheme(),
            features: {
                HighlightHoverItem: "off",
                OrientationIndicator: "off"
            },
            ui: {
                loadingSpinner: {
                    label: "WAGO WINSTA"
                },
                controls: {
                    rotation: false,
                    autozoom: false,
                    minDistance: 0,
                    maxDistance: Infinity,
                    minAzimuthAngle: -Infinity,
                    maxAzimuthAngle: Infinity,
                    minPolarAngle: 0,
                    maxPolarAngle: Math.PI
                },
                camera: {
                    near: 1000.0,
                    far: 500000.0,
                    fov: 1.0,
                    position: {
                        x: 0,
                        y: 10000,
                        z: 0
                    },
                    light: false,
                    logarithmicDepthBuffer: true
                },
                message: {
                    contextMenu: false,
                    boundingBox: true,
                    annotation: false,
                    messageBar: {
                        fixedWidth: false
                    }
                },
                dimensioning: {
                    lineColor: "#c60f2d",
                    textColor: "#14303f",
                    autoEnabled: "customDimensioning"
                },
                catalog: {
                    productId: true,
                    addButton: true,
                    infoButton: true,
                    imageFit: "contain",
                    with: null
                },
                mode: {
                    anchorOnly: true
                },
                contextMenu: {
                    vertical: true
                },
                toolbar: {
                    ViewFit: true,
                    ViewIsometric: false,
                    ViewTop: false,
                    ViewBottom: false,
                    ViewFront: false,
                    ViewRear: false,
                    ViewLeft: false,
                    ViewRight: false,
                    RotationLock: false,
                    select: true,
                    measure_distance: false,
                    transform_mode: false,
                    grid: false,
                    snap_objects: false,
                    configuration: false,
                    help: false,
                    autozoom: false,
                    dimensioning: {
                        singleDimensioning: false,
                        datumDimensioning: false,
                        customDimensioning: true
                    }
                },
                contextActions: {
                    zoom_first_item: "top",
                    open_configurator: true,
                    open_text_editor: true
                },
                globalDrop: true,
                background: `${window.encoway.baseUrl}/assets/background_visualization.png`,
                environment: `${window.encoway.baseUrl}/assets/environment_visualization.jpg`,
                environmentIntensity: 1,
                anchors: {
                    default: "#0077C8",
                    ok: "lime",
                    error: "red"
                },
                lights: [],
                components: {
                    catalog: "west",
                    header: false,
                    toolbar: true,
                    sidebar: "east"
                },
                widgets: {
                    progressSpinner: "northWest",
                    annotations: "southEast",
                    messages: "northEast",
                    orientationIndicator: false,
                    renderStatistics: "northWest"
                },
                sidebar: {
                    type: "panel"
                },
                progressSpinner: {
                    size: "medium",
                    labelPosition: "right"
                },
                hud: false,
                configuration: {
                    newComponents: true,
                    components: {
                        floorPlanUpload: FloorPlanUpload
                    }
                },
                keyboardShortcuts: {
                    cloudGraph: "Alt-b",
                    exportGeometry: "Alt-g",
                    transformMode: "Alt-c"
                }
            }
        }
    }
})
