import {
    ColumnActionsMode,
    IColumn,
    ImageFit,
    Image,
    IDetailsListProps,
    DetailsList,
    FontIcon,
    mergeStyles
} from "@fluentui/react"
import React from "react"

const iconClass = mergeStyles({
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "center",
    fontSize: 32,
    height: 50,
    width: 75
})

const defaultColumnProps = {
    isResizable: false,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (
        item?: any,
        index?: number | undefined,
        column?: IColumn | undefined
    ) =>
        column && (
            <span key={`row-${column.key}-${index}`}>
                {item.nodeProperties[column.key] || item[column.key]}
            </span>
        )
}

function renderProductImage(item: any) {
    return item.nodeProperties.characteristicValues.product_image ? (
        <Image
            src={
                item.nodeProperties.characteristicValues.product_image.values[0]
                    .uri
            }
            imageFit={ImageFit.contain}
            alt={`${item.nodeProperties.name} Product Image`}
            width={75}
            height={50}
        />
    ) : (
        <FontIcon iconName="PictureFill" className={iconClass} />
    )
}

export default function BomTable(props: Readonly<IDetailsListProps>) {
    const columns: IColumn[] = [
        {
            ...defaultColumnProps,
            key: "product_image",
            name: "",
            minWidth: 75,
            maxWidth: 75,
            onRender: renderProductImage
        },
        {
            ...defaultColumnProps,
            key: "quantity",
            name: "Qty.",
            minWidth: 50,
            maxWidth: 50
        },
        {
            ...defaultColumnProps,
            key: "id",
            name: "Item Nr.",
            minWidth: 100,
            maxWidth: 150
        },
        {
            ...defaultColumnProps,
            key: "name",
            name: "Product",
            minWidth: 100,
            maxWidth: 200
        }
    ]

    return <DetailsList {...props} columns={columns} />
}
