import { SelectionMode } from "@fluentui/react"
import { isEmpty, isNil } from "lodash"
import React, { useEffect, useState } from "react"

import { useDetermineBom } from "../../features/bom/useDetermineBom"
import BomTable from "./BomTable"

type Props = {
    type: "room" | "manufacturer"
}

type BomGroup = Map<string, any[]>

const NAName = "Not assigned"
const styles = {
    p: {
        marginBlockEnd: "0",
        fontWeight: "600"
    }
}

export default function ResultBom(props: Readonly<Props>) {
    const { determineBom } = useDetermineBom()

    const [boms, setBoms] = useState<Map<string, object[]>>(
        new Map<string, object[]>()
    )
    const [bomGroups, setBomGroups] = useState<BomGroup>(new Map())

    useEffect(() => {
        determineBom(props.type).then((bom) => {
            if (!isNil(bom) && !isEmpty(bom)) {
                setBoms(bom)
            }
        })
    }, [props.type])

    useEffect(() => {
        const groups: BomGroup = new Map()
        boms.forEach((bomItems, bomName) => {
            bomName = bomName === "not_assigned" ? "Not assigned" : bomName
            groups.set(bomName, prepareBomItems(bomItems))
        })
        setBomGroups(groups)
    }, [boms])

    function prepareBomItems(bomItems: any[]) {
        const preparedBomItems = {}
        bomItems.forEach((item) => {
            // @ts-ignore
            if (preparedBomItems[item.nodeProperties.id]) {
                // @ts-ignore
                preparedBomItems[item.nodeProperties.id].quantity += 1
            } else {
                // @ts-ignore
                preparedBomItems[item.nodeProperties.id] = { ...item }
            }
        })
        return Object.values(preparedBomItems)
    }

    function renderBomGroup(bomName: string, bomItems?: any[]) {
        if (!bomItems) {
            return
        }
        return (
            <div key={`bom_group_${bomName}`}>
                <p style={styles.p}>{bomName}</p>
                <hr />
                <BomTable items={bomItems} selectionMode={SelectionMode.none} />
            </div>
        )
    }

    const bomGroupsExists = bomGroups.size > 0
    return (
        <div style={{ minWidth: "40vw", maxHeight: "80vh", overflow: "auto" }}>
            {bomGroupsExists &&
                // @ts-ignore
                [...bomGroups]
                    .filter(([k]) => k !== NAName)
                    .map(([bomName, bomItems]) =>
                        renderBomGroup(bomName, bomItems)
                    )}
            {bomGroups.has(NAName) &&
                renderBomGroup(NAName, bomGroups.get(NAName))}
            {!bomGroupsExists && <p style={styles.p}>No Products added</p>}
        </div>
    )
}
