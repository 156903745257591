import { TextField } from "@fluentui/react"
import axios from "axios"
import { isEmpty, isNil } from "lodash"
import React, { useState } from "react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    selectSavedConfigurationCode,
    selectVisualization,
    setSavedConfigurationCode,
    setVisualizationChanged
} from "../../app/visualizationSlice"
import Dialog from "../Dialog/Dialog"

export function LoadConfigurationDialog(props: any) {
    const [value, setValue] = useState<string>("")
    const [error, setError] = useState<string | undefined>(undefined)
    const savedConfigurationCode = useAppSelector(selectSavedConfigurationCode)
    const dispatch = useAppDispatch()
    const visualization = useAppSelector(selectVisualization)

    const onClose = () => {
        props.onCloseDialog()
    }

    const loadConfiguration = (configurationData: any) => {
        visualization
            .loadState(JSON.parse(configurationData).visualizationData)
            .then(() => {
                dispatch(setSavedConfigurationCode(value))
                dispatch(setVisualizationChanged(true))
                onClose()
            })
            .catch(() =>
                setError("There was an error loading the configuration.")
            )
    }

    const onChangeHandler = (value: string) => {
        setError("")
        setValue(value)
        if (value === savedConfigurationCode) {
            setError("Configuration code already loaded.")
        }
    }

    const loadConfigurationCode = (configurationCode: string) => {
        axios
            .get(`api/configuration/configurations/code/${configurationCode}`)
            .then((response) => {
                if (response.status === 200) {
                    loadConfiguration(response.data)
                }
            })
            .catch(() =>
                setError("Please enter a valid code to load a configuration.")
            )
    }

    const onClickPrimary = () => {
        loadConfigurationCode(value)
    }

    const isLoadButtonActive =
        !isNil(value) && !isEmpty(value) && value.length === 8
    return (
        <Dialog
            title="Load configuration"
            showPrimaryButton={isLoadButtonActive}
            showSecondaryButton={true}
            primaryButtonTitle="Load"
            secondaryButtonTitle="Cancel"
            onClickPrimary={onClickPrimary}
            onClickSecondary={onClose}
            onClose={onClose}
        >
            <TextField
                onChange={(event) => onChangeHandler(event.currentTarget.value)}
                label="Configuration code (8 digits)"
                errorMessage={error}
                aria-required={true}
                required={true}
                value={value}
                placeholder="Please enter the configuration code (8 digits)"
            />
        </Dialog>
    )
}
