import { pdfToImageConverter } from "../../../utils/pdfToImageConverter"

export async function getBase64Image(file: File) {
    if (file.type === "application/pdf") {
        return await pdfToImageConverter(file, "jpeg")
    } else {
        const fileContent = await read(file)
        if (typeof fileContent === "string") {
            return fileContent
        } else {
            return ""
        }
    }
}

export function read(file: File) {
    const reader = new FileReader()
    return new Promise(function (resolve) {
        reader.onload = async () => {
            const base64Image = reader.result
            if (typeof base64Image === "string") {
                resolve(base64Image)
            } else {
                throw new DOMException(
                    "An error occurred while reading the image."
                )
            }
        }
        reader.readAsDataURL(file)
    })
}
