import { Layer } from "@fluentui/react"
import React from "react"

import { useAppSelector } from "../../app/hooks"
import { selectToasts } from "../../app/toastSlice"
import Toast from "./Toast"

const TOAST_SPACING = "1rem"

export default function ToastsContainer() {
    const toasts = useAppSelector(selectToasts)

    const layerStyles: React.CSSProperties = {
        display: "flex",
        gap: "0.5rem",
        position: "fixed",
        top: TOAST_SPACING,
        right: TOAST_SPACING,
        alignItems: "flex-end",
        flexDirection: "column-reverse"
    }

    return (
        <Layer>
            {toasts.length > 0 && (
                <div style={layerStyles}>
                    {toasts.map((toast, index) => (
                        <Toast
                            key={`toast_div_${toast.message}_${index}`}
                            {...toast}
                        />
                    ))}
                </div>
            )}
        </Layer>
    )
}
