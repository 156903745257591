import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import Dialog from "../Dialog/Dialog"
import Navigation, { LinkPropsAdvanced } from "../Navigation/Navigation"

export function VisualizationResultDialog() {
    const navigate = useNavigate()
    const location = useLocation()

    const links: LinkPropsAdvanced[] = [
        {
            title: "Rooms",
            to: "rooms",
            relative: "path",
            state: {
                backgroundLocation: location.state.backgroundLocation
            }
        },
        {
            title: "Manufacturers",
            to: "manufacturers",
            relative: "path",
            state: {
                backgroundLocation: location.state.backgroundLocation
            }
        }
    ]

    const onClose = () => {
        navigate("/")
    }

    return (
        <Dialog
            title="Result"
            showPrimaryButton={false}
            showSecondaryButton={false}
            onClose={onClose}
        >
            <Navigation links={links} />
            <Outlet />
        </Dialog>
    )
}
