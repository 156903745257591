import { isNil } from "lodash"
import * as pdfjsLib from "pdfjs-dist"
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

export async function pdfToImageConverter(
    file: File,
    imageType: "png" | "jpeg"
) {
    const pdfContent = await fileToArrayBuffer(file)
    if (isNil(pdfContent) || typeof pdfContent === "string") {
        throw new DOMException(
            "An error occurred while converting PDF to image."
        )
    }

    const loadingTask = pdfjsLib.getDocument(pdfContent)
    const pdf = await loadingTask.promise
    const page = await pdf.getPage(1) // Get the first page
    const viewport = page.getViewport({ scale: 1 })
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (isNil(context)) {
        throw new DOMException(
            "An error occurred while converting PDF to image."
        )
    }

    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
        intent: "print",
        canvasContext: context,
        viewport
    }
    await page.render(renderContext).promise
    return canvas.toDataURL(`image/${imageType}`)
}

function fileToArrayBuffer(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = () => {
            reader.abort()
            reject(new DOMException("Problem parsing input file."))
        }

        reader.onload = () => {
            resolve(reader.result)
        }

        reader.readAsArrayBuffer(file)
    })
}
