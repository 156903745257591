import React from "react"

import Dialog from "../../Dialog/Dialog"

type Props = {
    fileName: string
    onClickPrimaryHandler: () => void
    onClickSecondaryHandler: () => void
}

export function FloorPlanUploadConfirmationDialog(props: Readonly<Props>) {
    return (
        <Dialog
            title="Exit Upload"
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonTitle="Exit"
            secondaryButtonTitle="Go back"
            onClickPrimary={props.onClickPrimaryHandler}
            onClickSecondary={props.onClickSecondaryHandler}
        >
            <>
                <div>
                    Are you sure you want to exit without saving the active
                    changes?
                </div>
                <br />
                <div>Unsaved Image: {props.fileName}</div>
            </>
        </Dialog>
    )
}
