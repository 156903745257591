import React from "react"
import { Link, LinkProps } from "react-router-dom"

import NavigationTab from "./NavigationTab"

export type LinkPropsAdvanced = LinkProps & {
    title: string
}

type Props = {
    links: LinkPropsAdvanced[]
}

export default function Navigation(props: Props) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                height: "3rem",
                width: "100%",
                backgroundColor: "#eff0f1"
            }}
        >
            {props.links.map((link) => (
                <Link
                    key={`link_${link.to}`}
                    to={link.to}
                    relative={link.relative}
                    state={link.state}
                >
                    <NavigationTab
                        title={link.title}
                        urlPath={link.to as string}
                    />
                </Link>
            ))}
        </div>
    )
}
