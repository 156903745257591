import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { Visualization } from "@encoway/visual-editor"

import { RootState } from "./store"

export interface VisualizationState {
    visualization: object[]
    visualizationChanged: boolean
    savedConfigurationCode: string
}

const initialState: VisualizationState = {
    visualization: [],
    visualizationChanged: false,
    savedConfigurationCode: ""
}

export const visualizationSlice = createSlice({
    name: "visualization",
    initialState,
    reducers: {
        setVisualization: (
            state,
            action: PayloadAction<Visualization>
        ): void => {
            state.visualization.push(action.payload)
        },
        setVisualizationChanged: (
            state,
            action: PayloadAction<boolean>
        ): void => {
            state.visualizationChanged = action.payload
        },
        setSavedConfigurationCode: (
            state,
            action: PayloadAction<string>
        ): void => {
            state.savedConfigurationCode = action.payload
        }
    }
})

export const {
    setVisualization,
    setVisualizationChanged,
    setSavedConfigurationCode
} = visualizationSlice.actions

export const selectVisualization = (state: RootState) =>
    state.visualization.visualization[0] as Visualization

export const hasVisualizationChanged = (state: RootState) =>
    state.visualization.visualizationChanged

export const selectSavedConfigurationCode = (state: RootState) =>
    state.visualization.savedConfigurationCode

export default visualizationSlice.reducer
