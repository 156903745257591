import { MessageBarType } from "@fluentui/react"
import React from "react"

import { useAppDispatch, useAppSelector } from "../app/hooks"
import { addToast } from "../app/toastSlice"
import {
    hasVisualizationChanged,
    selectVisualization,
    setSavedConfigurationCode
} from "../app/visualizationSlice"
import { useDetermineBom } from "../features/bom/useDetermineBom"
import { DefaultPrimaryButton } from "./DefaultPrimaryButton/DefaultPrimaryButton"

export default function SaveConfigurationButton() {
    const visualization = useAppSelector(selectVisualization)
    const visualizationChanged = useAppSelector(hasVisualizationChanged)
    const dispatch = useAppDispatch()
    const { determineBom } = useDetermineBom()

    const saveConfigurationResult = async () => {
        await determineBom("room")

        visualization.saveState().then((state) => {
            const baseUrl = window.encoway.baseUrl
            // TODO: Change to axios
            fetch(`${baseUrl}/api/configuration/configurations/code`, {
                method: "POST",
                body: JSON.stringify({
                    visualizationData: state
                })
            }).then(async (response) => {
                if (response.status === 201) {
                    const result = await response.json()
                    dispatch(setSavedConfigurationCode(result))
                    dispatch(
                        addToast({
                            message: "Configuration saved",
                            type: MessageBarType.success,
                            duration: 6000
                        })
                    )
                } else {
                    dispatch(
                        addToast({
                            message: "Failed to save the configuration",
                            type: MessageBarType.error,
                            duration: 6000
                        })
                    )
                }
            })
        })
    }

    return (
        <DefaultPrimaryButton
            disabled={!visualization || !visualizationChanged}
            onClick={() => {
                saveConfigurationResult()
            }}
        >
            Save
        </DefaultPrimaryButton>
    )
}
