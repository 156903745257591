import { Image, ImageFit, PrimaryButton, TextField } from "@fluentui/react"
import { isEmpty, isNil } from "lodash"
import React, { useEffect, useState } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"
import { ICommonProps } from "@encoway/cui-configurator-components/dist/esm/common.types"

import { getTheme } from "../../../theme"
import Dialog from "../../Dialog/Dialog"
import { FloorPlanUploadConfirmationDialog } from "./FloorPlanUploadConfirmationDialog"
import { getBase64Image } from "./floorPlanUploadFileHelper"

export function FloorPlanUpload(props: Readonly<ICommonProps<ParameterTO>>) {
    const [showFloorPlanUpload, setShowFloorPlanUpload] =
        useState<boolean>(false)
    const [showConfirmationDialog, setShowConfirmationDialog] =
        useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [floorPlanWidth, setFloorPlanWidth] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [base64Image, setBase64Image] = useState<string>("")

    const node = props.options?.visualizationNode

    useEffect(() => {
        setErrorMessage("")
        setBase64Image("")
    }, [fileName])

    const theme = getTheme()

    function determineDimensions(image: string) {
        return new Promise((resolve) => {
            const img = new window.Image()
            img.src = image
            img.onload = () => {
                resolve({
                    floorplan_real_width: img.width,
                    floorplan_real_height: img.height,
                    ...calculateFloorplanDimensions(img)
                })
            }
        })
    }

    function calculateFloorplanDimensions(img: HTMLImageElement) {
        const fpWidth = Number(floorPlanWidth.replace(",", "."))
        const fpHeight = (img.height / img.width) * fpWidth
        return {
            floorplan_width: fpWidth * 100,
            floorplan_height: fpHeight * 100
        }
    }

    async function onClickPrimary() {
        const dimensions = await determineDimensions(base64Image)
        // @ts-ignore
        node.state.setState({ floorplan_image: base64Image, ...dimensions })
        setShowFloorPlanUpload(false)
        setFileName("")
        setBase64Image("")
        setFloorPlanWidth("")
    }

    function onClickPrimaryConfirmationDialog() {
        setShowConfirmationDialog(false)
        setShowFloorPlanUpload(false)
    }

    const onClose = () => {
        if (!isEmpty(fileName)) {
            setShowConfirmationDialog(true)
        } else {
            setShowFloorPlanUpload(false)
        }
    }

    async function onChangeHandler(event: Event) {
        const target = event.target as HTMLInputElement
        if (isNil(target.files)) {
            setErrorMessage("An error occurred! No file could be found!")
            return
        }
        if (target.files.length > 0) {
            const file = target.files[0]
            setFileName(file.name)
            const base64Image = await getBase64Image(file)
            setBase64Image(base64Image)
        }
    }

    return (
        <>
            <PrimaryButton
                onClick={() => setShowFloorPlanUpload(true)}
                text="Upload Floor plan"
            />
            {showFloorPlanUpload && (
                <Dialog
                    title="Upload Floor plan"
                    showPrimaryButton={
                        !isEmpty(base64Image) &&
                        !isEmpty(floorPlanWidth) &&
                        isEmpty(errorMessage)
                    }
                    showSecondaryButton={true}
                    primaryButtonTitle="Save"
                    secondaryButtonTitle="Cancel"
                    onClickPrimary={onClickPrimary}
                    onClickSecondary={onClose}
                    onClose={onClose}
                >
                    <TextField
                        styles={{
                            root: {
                                "input::file-selector-button": {
                                    backgroundColor: theme.palette.themePrimary,
                                    color: "white",
                                    padding: "0px 16px",
                                    border: "1px solid rgb(110, 200, 0)",
                                    height: "32px",
                                    cursor: "pointer"
                                }
                            },
                            fieldGroup: {
                                border: "none"
                            }
                        }}
                        type="file"
                        id="image"
                        title="Upload Image"
                        accept="image/png,image/jpeg,application/pdf"
                        onChange={async (event) =>
                            onChangeHandler(event.nativeEvent)
                        }
                        errorMessage={errorMessage}
                    />
                    <br />
                    <TextField
                        type="number"
                        label="Width of the floor plan (meters)"
                        onChange={(event) => {
                            setFloorPlanWidth(event.currentTarget.value)
                        }}
                        value={floorPlanWidth}
                        placeholder="Please enter the width of the entire floor plan in meters"
                    />

                    {base64Image && (
                        <>
                            <div
                                style={{
                                    marginTop: "1rem",
                                    fontSize: "1.25rem",
                                    fontWeight: 600
                                }}
                            >
                                Preview
                            </div>
                            <Image
                                shouldFadeIn={true}
                                imageFit={ImageFit.contain}
                                src={base64Image}
                                style={{ maxHeight: "50vh" }}
                            />
                        </>
                    )}
                    {showConfirmationDialog && (
                        <FloorPlanUploadConfirmationDialog
                            fileName={fileName}
                            onClickPrimaryHandler={
                                onClickPrimaryConfirmationDialog
                            }
                            onClickSecondaryHandler={() =>
                                setShowConfirmationDialog(false)
                            }
                        />
                    )}
                </Dialog>
            )}
        </>
    )
}
