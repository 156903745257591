import React from "react"
import { Outlet } from "react-router-dom"

import ActionToolbar from "./components/ActionToolbar/ActionToolbar"
import ToastsContainer from "./components/Toast/ToastsContainer"

export default function Layout() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}
        >
            <ToastsContainer />
            <ActionToolbar />
            <div
                style={{
                    minHeight: "70vh",
                    maxHeight: "84vh",
                    display: "flex",
                    flex: "1 1 0%"
                }}
            >
                <Outlet />
            </div>
        </div>
    )
}
