import React from "react"
import { Link, useLocation } from "react-router-dom"

import CsvExportButton from "../CsvExportButton"
import { DefaultPrimaryButton } from "../DefaultPrimaryButton/DefaultPrimaryButton"
import LoadConfigurationButton from "../LoadConfiguration/LoadConfigurationButton"
import SaveConfigurationButton from "../SaveConfigurationButton"
import SavedConfigurationCodeDisplay from "./SavedConfigurationCodeDisplay"

export default function ActionToolbar() {
    const location = useLocation()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                padding: "1rem",
                justifyContent: "right"
            }}
        >
            <Link to="/result/rooms" state={{ backgroundLocation: location }}>
                <DefaultPrimaryButton>Result</DefaultPrimaryButton>
            </Link>
            <CsvExportButton />
            <SaveConfigurationButton />
            <LoadConfigurationButton />
            <SavedConfigurationCodeDisplay />
        </div>
    )
}
