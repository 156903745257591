import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../../app/store"

export const StructuredBomTypes = ["room", "manufacturer"] as const

type StructuredBom = {
    type: (typeof StructuredBomTypes)[number]
    data: Map<string, object[]>
}

export interface StructuredBomState {
    structuredBom: Map<StructuredBom["type"], StructuredBom["data"]>
}

const initialState: StructuredBomState = {
    structuredBom: new Map<StructuredBom["type"], StructuredBom["data"]>()
}

export const bomSlice = createSlice({
    name: "bom",
    initialState,
    reducers: {
        setStructuredBom: (
            state,
            action: PayloadAction<StructuredBomState["structuredBom"]>
        ): void => {
            state.structuredBom = action.payload
        }
    }
})

export const { setStructuredBom } = bomSlice.actions

export default bomSlice.reducer

export const selectStructuredBom = (state: RootState) => state.bom.structuredBom

export const selectStructuredBomByRoom = (state: RootState) =>
    state.bom.structuredBom.get("room")

export const selectStructuredBomByManufacturer = (state: RootState) =>
    state.bom.structuredBom.get("manufacturer")
