import { PrimaryButton } from "@fluentui/react"
import type { IButtonProps } from "@fluentui/react/lib/components/Button/Button.types"
import React from "react"

export function DefaultPrimaryButton(props: Readonly<IButtonProps>) {
    return (
        <PrimaryButton
            styles={{
                root: {
                    padding: "12px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    border: "2px solid transparent"
                },
                rootHovered: {
                    borderTopColor: "transparent",
                    borderRightColor: "#1f2837",
                    borderBottomColor: "#1f2837",
                    borderLeftColor: "transparent"
                },
                rootFocused: {
                    borderTopColor: "#1f2837",
                    borderRightColor: "transparent",
                    borderBottomColor: "transparent",
                    borderLeftColor: "#1f2837"
                }
            }}
            {...props}
        >
            {props.children}
        </PrimaryButton>
    )
}
