import { Stack, Text } from "@fluentui/react"
import React from "react"

import { useAppSelector } from "../../app/hooks"
import { selectSavedConfigurationCode } from "../../app/visualizationSlice"
import { getTheme } from "../../theme"

export default function SavedConfigurationCodeDisplay() {
    const visualizationCode = useAppSelector(selectSavedConfigurationCode)
    if (!visualizationCode) {
        return <></>
    }

    const theme = getTheme()
    return (
        <Stack
            horizontalAlign="center"
            verticalAlign="center"
            style={{
                backgroundColor: theme.palette.themePrimary,
                borderRadius: "2px",
                width: "10rem"
            }}
        >
            <Text
                variant="medium"
                style={{
                    fontSize: "1rem",
                    color: theme.palette.white,
                    fontWeight: 600
                }}
            >
                Code: {visualizationCode}
            </Text>
        </Stack>
    )
}
