import { isEmpty } from "lodash"

import { Product } from "@encoway/c-services-js-client"
import { Constants, Visualization } from "@encoway/visual-editor"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    hasVisualizationChanged,
    selectVisualization,
    setVisualizationChanged
} from "../../app/visualizationSlice"
import {
    selectStructuredBom,
    setStructuredBom,
    StructuredBomTypes
} from "./bomSlice"

export const useDetermineBom = () => {
    const visualizationChanged = useAppSelector(hasVisualizationChanged)
    const visualization = useAppSelector(selectVisualization)
    const structuredBom = useAppSelector(selectStructuredBom)
    const dispatch = useAppDispatch()

    const determineBom = async (
        bomType: (typeof StructuredBomTypes)[number]
    ) => {
        if (visualizationChanged && visualization) {
            const result = await visualization.result(Constants.BOM.Tree)
            // @ts-ignore
            const nodes: object[] | undefined = result.state.nodes
            if (nodes && !isEmpty(nodes)) {
                const structure = buildStructuredBom(nodes, visualization)
                dispatch(setStructuredBom(structure))
                dispatch(setVisualizationChanged(false))
                return structure.get(bomType)
            }
        }
        return Promise.resolve(structuredBom.get(bomType))
    }

    return { determineBom }
}

const buildStructuredBom = (nodes: object[], visualization: Visualization) =>
    nodes.reduce(
        (
            structure: Map<
                (typeof StructuredBomTypes)[number],
                Map<string, object[]>
            >,
            node: object
        ) => {
            StructuredBomTypes.forEach((bomType) => {
                const typeStructure =
                    structure.get(bomType) ?? new Map<string, object[]>()
                createStructuredBomForType(
                    typeStructure,
                    prepareNode(node, visualization),
                    bomType
                )
                structure.set(bomType, typeStructure)
            })
            return structure
        },
        new Map<(typeof StructuredBomTypes)[number], Map<string, object[]>>()
    )

const createStructuredBomForType = (
    acc: Map<string, object[]>,
    node: object,
    type: (typeof StructuredBomTypes)[number]
): void => {
    // @ts-ignore
    if (node.state.excludeFromBOM) {
        return
    }
    // @ts-ignore
    const value = node.state[type] ?? "not_assigned"
    const structure = acc.get(value) ?? []
    acc.set(value, [...structure, node])
}

const prepareNode = (node: any, visualization: Visualization) => {
    const visuNode = visualization.cloud.find(node.id)
    // @ts-ignore
    const nodeItem: Product = visuNode._props.product.product

    return {
        ...node,
        nodeProperties: nodeItem,
        quantity: 1
    }
}
