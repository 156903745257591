import { createSlice, current } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { isEqual } from "lodash"

import { ToastProps } from "../components/Toast/Toast"
import type { RootState } from "./store"

interface ToastState {
    toasts: ToastProps[]
}

const initialState: ToastState = {
    toasts: []
}

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<ToastProps>) => {
            const activeToast: ToastProps = {
                ...action.payload,
                duration: action.payload.duration || undefined
            }

            state.toasts = [
                ...state.toasts.filter(
                    (item) => !isEqual(current(item), activeToast)
                ),
                activeToast
            ]
        },
        removeToast: (state, action: PayloadAction<ToastProps>) => {
            state.toasts = state.toasts.filter(
                (item) => !isEqual(current(item), action.payload)
            )
        }
    }
})

export const { addToast, removeToast } = toastSlice.actions

export const selectToasts = (state: RootState) => state.toasts.toasts

export default toastSlice.reducer
