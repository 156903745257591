import React from "react"

import { TabButton } from "@encoway/cui-configurator-components"

import { getTheme } from "../../theme"

type Props = {
    title: string
    urlPath: string
}

export default function NavigationTab({ title, urlPath }: Readonly<Props>) {
    const theme = getTheme()

    const isActive = window.location.href.includes(urlPath)
    const backgroundColor = isActive ? theme.palette.white : "#dedfe1"
    const borderTopColor = isActive ? theme.palette.themePrimary : "transparent"
    const tabStyle = {
        button: {
            backgroundColor,
            borderTop: `2px solid ${borderTopColor}`,
            ":focus": {
                borderTop: `2px solid ${borderTopColor}`
            }
        }
    }

    return <TabButton text={title} styles={tabStyle} />
}
