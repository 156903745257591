import { TextField } from "@fluentui/react"
import { isEmpty, isNil } from "lodash"
import React, { useState } from "react"

import Dialog from "./Dialog/Dialog"

interface SetNodeStateValueModalProps {
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
    node: any
    dialogTitle: string
    textFieldLabel: string
    type: "room" | "manufacturer"
}

export function VisualizationSetStateDialog(
    props: Readonly<SetNodeStateValueModalProps>
) {
    const [initialStateValue] = useState<string>(
        props.node.state.state[props.type] ?? ""
    )
    const [stateValue, setStateValue] = useState<string>(initialStateValue)
    const [error, setError] = useState<string | undefined>(undefined)
    const [showConfirmationDialog, setShowConfirmationDialog] =
        useState<boolean>(false)

    const onClickPrimary = () => {
        if (isNil(stateValue) || isEmpty(stateValue)) {
            setError(`Please enter a value for 
                ${props.textFieldLabel}`)
        } else {
            props.node.state.setState({ [props.type]: stateValue })
            props.setShowDialog(false)
        }
    }

    const onClickPrimaryConfirmationDialog = () => {
        setShowConfirmationDialog(false)
        props.setShowDialog(false)
    }

    const onClose = () => {
        if (
            stateValue !== initialStateValue &&
            stateValue !== "" &&
            initialStateValue !== ""
        ) {
            setShowConfirmationDialog(true)
        } else {
            props.setShowDialog(false)
        }
    }

    const onClickCancel = () => {
        onClose()
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            onClickPrimary()
        }
    }

    return (
        <Dialog
            title={props.dialogTitle}
            showPrimaryButton={true}
            showSecondaryButton={true}
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            onClickPrimary={onClickPrimary}
            onClickSecondary={onClickCancel}
            onClose={onClose}
        >
            <TextField
                onChange={(event) => {
                    setStateValue(event.currentTarget.value)
                }}
                onKeyDown={onKeyDown}
                label={props.textFieldLabel}
                errorMessage={error}
                aria-required={true}
                required={true}
                value={stateValue}
                placeholder={props.textFieldLabel}
            />

            {showConfirmationDialog && (
                <Dialog
                    title="Unsaved Changes"
                    showPrimaryButton={true}
                    showSecondaryButton={true}
                    primaryButtonTitle="Exit"
                    secondaryButtonTitle="Go back"
                    onClickSecondary={() => {
                        setShowConfirmationDialog(false)
                    }}
                    onClickPrimary={() => {
                        onClickPrimaryConfirmationDialog()
                    }}
                >
                    <div>
                        Are you sure you want to exit without saving the active
                        changes?
                    </div>
                    <br />
                    <div>Current: {props.node.state.state[props.type]}</div>
                    <div>Unsaved Changes: {stateValue}</div>
                </Dialog>
            )}
        </Dialog>
    )
}
