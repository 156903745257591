import {
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    Stack
} from "@fluentui/react"
import React, { useEffect, useState } from "react"

import { useAppDispatch } from "../../app/hooks"
import { removeToast } from "../../app/toastSlice"

export type ToastProps = {
    message: string
    type: MessageBarType
    duration?: number
}

export default function Toast(props: ToastProps) {
    const [indicatorCompletion, setIndicatorCompletion] = useState<number>(1)
    const dispatch = useAppDispatch()

    const onDismiss = () => {
        dispatch(removeToast(props))
    }

    useEffect(() => {
        setIndicatorCompletion(1)
    }, [props])

    useEffect(() => {
        if (props.duration) {
            const decrementAmount = 100 / props.duration
            const timer = setInterval(() => {
                setIndicatorCompletion((prev) => {
                    if (prev <= 0) {
                        clearInterval(timer)
                        onDismiss()
                        return 0
                    }
                    return prev - decrementAmount
                })
            }, 100)
            return () => clearInterval(timer)
        }
    }, [props.duration, onDismiss])

    return (
        <Stack
            verticalAlign="end"
            styles={{
                root: {
                    zIndex: 1000
                }
            }}
        >
            <MessageBar
                messageBarType={props.type}
                isMultiline={false}
                onDismiss={onDismiss}
                dismissButtonAriaLabel="Close"
                styles={{
                    root: {
                        width: "fit-content"
                    }
                }}
            >
                {props.message}
            </MessageBar>
            {props.duration && (
                <ProgressIndicator
                    styles={{
                        root: {
                            position: "absolute",
                            bottom: "0",
                            width: "100%"
                        },
                        itemProgress: {
                            padding: "0"
                        },
                        progressBar: {
                            backgroundColor: "Gray"
                        },
                        progressTrack: {
                            backgroundColor: "#FFF"
                        }
                    }}
                    percentComplete={indicatorCompletion}
                />
            )}
        </Stack>
    )
}
