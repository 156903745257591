import {
    ActionButton,
    Dialog as FluentUIDialog,
    DialogFooter,
    DialogType,
    FontIcon,
    mergeStyleSets,
    PrimaryButton
} from "@fluentui/react"
import React, { ReactNode } from "react"

import { getTheme } from "../../theme"

const styles = {
    custom: {
        actionButton: {
            icon: {
                color: getTheme().palette.black,
                margin: "0px"
            },
            iconHovered: {
                color: getTheme().palette.themePrimary
            },
            rootHovered: {
                borderColor: "transparent"
            }
        }
    },
    icons: {
        custom: mergeStyleSets({
            primaryIcon: {
                fontWeight: "bold",
                fontSize: 14,
                height: 16,
                width: 16,
                marginRight: "6px"
            }
        }),
        decreaseArrowIcon: { iconName: "DecreaseIndentArrow" }
    }
}

type Props = {
    title: string
    showPrimaryButton: boolean
    showSecondaryButton: boolean
    primaryButtonTitle?: string
    secondaryButtonTitle?: string
    onClickPrimary?: () => void
    onClickSecondary?: () => void
    onClose?: () => void
    children: ReactNode | string
}

export default function Dialog({
    title,
    showPrimaryButton,
    showSecondaryButton,
    primaryButtonTitle,
    secondaryButtonTitle,
    onClickPrimary,
    onClickSecondary,
    onClose,
    children
}: Readonly<Props>) {
    const generatedContentProps = {
        type: onClose ? DialogType.close : DialogType.normal,
        title
    }
    return (
        <FluentUIDialog
            dialogContentProps={generatedContentProps}
            onDismiss={onClose}
            hidden={false}
            maxWidth="60vw"
            minWidth="30vw"
        >
            <>
                {children}
                {showPrimaryButton || showSecondaryButton ? (
                    <DialogFooter
                        styles={{
                            actionsRight: {
                                flexDirection: showPrimaryButton
                                    ? "row-reverse"
                                    : "row",
                                justifyContent: "space-between"
                            }
                        }}
                    >
                        {showPrimaryButton &&
                            primaryButtonTitle &&
                            onClickPrimary && (
                                <PrimaryButton onClick={onClickPrimary}>
                                    <FontIcon
                                        aria-label="ChevronRight"
                                        iconName="ChevronRight"
                                        className={
                                            styles.icons.custom.primaryIcon
                                        }
                                    />
                                    {primaryButtonTitle}
                                </PrimaryButton>
                            )}
                        {showSecondaryButton &&
                            secondaryButtonTitle &&
                            onClickSecondary && (
                                <ActionButton
                                    iconProps={styles.icons.decreaseArrowIcon}
                                    onClick={onClickSecondary}
                                    styles={styles.custom.actionButton}
                                >
                                    {secondaryButtonTitle}
                                </ActionButton>
                            )}
                    </DialogFooter>
                ) : null}
            </>
        </FluentUIDialog>
    )
}
