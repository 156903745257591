import React, { useState } from "react"

import { DefaultPrimaryButton } from "../DefaultPrimaryButton/DefaultPrimaryButton"
import { LoadConfigurationDialog } from "./LoadConfigurationDialog"

export default function LoadConfigurationButton() {
    const [showDialog, setShowDialog] = useState<boolean>(false)

    return (
        <>
            <DefaultPrimaryButton onClick={() => setShowDialog(true)}>
                Load
            </DefaultPrimaryButton>
            {showDialog && (
                <LoadConfigurationDialog
                    onCloseDialog={() => setShowDialog(false)}
                />
            )}
        </>
    )
}
