import { ICommandBarItemProps } from "@fluentui/react"
import React, { useState } from "react"

import {
    Visualization,
    VisualizationProps
} from "@encoway/cui-application-components"

import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectNode, setNode } from "../app/nodeSlice"
import { VisualizationSetStateDialog } from "./VisualizationSetStateDialog"

export function WagoVisualization(props: VisualizationProps) {
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const node = useAppSelector(selectNode)
    const dispatch = useAppDispatch()

    return (
        <>
            {node !== undefined &&
                showDialog &&
                (node.payload.state.get("room") ? (
                    <VisualizationSetStateDialog
                        setShowDialog={setShowDialog}
                        dialogTitle="Set Room"
                        textFieldLabel="Room Name"
                        node={node.payload}
                        type="room"
                    />
                ) : (
                    <VisualizationSetStateDialog
                        setShowDialog={setShowDialog}
                        dialogTitle="Set Manufacturer"
                        textFieldLabel="Manufacturer Name"
                        node={node.payload}
                        type="manufacturer"
                    />
                ))}

            {/* @ts-ignore */}
            <Visualization {...props} onContextMenu={createContextMenu} />
        </>
    )

    function createContextMenu(
        nodes: object,
        dismissMenu: () => void,
        contextAnchor: object | null,
        hitInformation: { localPosition: object }
    ): { replace: boolean; items: ICommandBarItemProps[] } {
        const node = Array.isArray(nodes) ? nodes[nodes.length - 1] : nodes
        // @ts-ignore
        if (node.state.get("script_element") === "cable") {
            return {
                items: [
                    {
                        key: "add-segment",
                        name: "Füge Abschnitt hinzu",
                        iconOnly: false,
                        iconProps: { iconName: "ChartXAngle" },
                        onClick: () => {
                            node.graph.cloud
                                .mount(
                                    node.id(),
                                    "article",
                                    null,
                                    {
                                        id: "Waypoint",
                                        position: hitInformation.localPosition
                                    },
                                    "default"
                                )
                                .then((mountedNode: object) => {
                                    // @ts-ignore
                                    mountedNode.props.target = mountedNode.id()
                                    dismissMenu()
                                })
                        }
                    }
                ],
                replace: true
            }
        }

        // @ts-ignore
        if (node.state.get("room")) {
            return {
                items: [
                    {
                        key: "edit-room",
                        name: "Edit Room",
                        iconOnly: false,
                        iconProps: { iconName: "Room" },
                        onClick: () => {
                            dispatch(setNode(node))
                            setShowDialog(true)
                            dismissMenu()
                        }
                    }
                ],
                replace: false
            }
        }
        return { replace: false, items: [] }
    }
}
