import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import type { RootState } from "./store"

interface NodeState {
    node: any
}

const initialState: NodeState = {
    node: undefined
}

export const nodeSlice = createSlice({
    name: "node",
    initialState,
    reducers: {
        setNode: (state, action: PayloadAction<any>): void => {
            state.node = action
        }
    }
})

export const { setNode } = nodeSlice.actions

export const selectNode = (state: RootState) => state.node.node

export default nodeSlice.reducer
