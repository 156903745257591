import React from "react"
import { Route, Routes, useLocation } from "react-router-dom"

import Layout from "./Layout"
import { Configurator } from "./components/Configurator"
import ResultBom from "./components/ResultDialog/ResultBom"
import { VisualizationResultDialog } from "./components/ResultDialog/VisualizationResultDialog"

declare global {
    interface Window {
        encoway: {
            baseUrl: string
            initialized: boolean
            startConfiguration: (productId: string, elementId: string) => void
        }
    }
}

interface AppProps {
    productId: string
}

export const App = ({ productId }: Readonly<AppProps>) => {
    const location = useLocation()
    const state = location.state as { backgroundLocation?: Location }

    return (
        <>
            <Routes location={state?.backgroundLocation || location}>
                <Route path="/" element={<Layout />}>
                    <Route
                        index
                        element={<Configurator productId={productId} />}
                    />
                </Route>
            </Routes>

            {/* Only show the popup if the background was successfully set */}
            {state?.backgroundLocation && (
                <Routes>
                    <Route
                        path="/result"
                        element={<VisualizationResultDialog />}
                    >
                        <Route
                            path="rooms"
                            element={<ResultBom type="room" />}
                        />
                        <Route
                            path="manufacturers"
                            element={<ResultBom type="manufacturer" />}
                        />
                    </Route>
                </Routes>
            )}
        </>
    )
}
